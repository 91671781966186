import { App } from "vue"
import VueApexCharts from "vue3-apexcharts"

/**
 * Initialize VueApexChart component
 * @param app vue instance
 */
export function initApexCharts(app: App<Element>) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Unreachable code error
  app.use(VueApexCharts)
}
