import { MutationTree } from "vuex"
import { Mutations } from "@/store/enums/StoreEnums"
import { State } from "./state"
import { WatermarkInfo, WatermarkSelect } from "@/interfaces/IWatermark"

export default <MutationTree<State>>{
  [Mutations.SET_WATERMARKS](state, payload) {
    state.watermarks = payload
  },
  [Mutations.SET_WATERMARK_INFO](state, payload) {
    state.watermarkInfo = payload
  },
  [Mutations.SET_WATERMARK_SELECT](state, payload) {
    state.watermarkSelect = payload
  },

  [Mutations.RESET_WATERMARK_INFO](state) {
    state.watermarkInfo = {} as WatermarkInfo
    state.watermarkSelect = {} as WatermarkSelect[]
  }
}
