import ApiService from "@/core/services/ApiService"
import { ActionTree } from "vuex"
import { Actions, Mutations } from "@/store/enums/StoreEnums"
import { State } from "./state"
export default <ActionTree<State, any>>{
  [Actions.GET_UIFORMS](store, payload) {
    return ApiService.query(`/admin/uiforms`, { params: payload })
      .then(({ data }) => {
        store.commit(Mutations.SET_UIFORMS, data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.GET_UIFORM_INFO](store, payload) {
    return ApiService.get(`/admin/uiform/${payload}`)
      .then(({ data }) => {
        store.commit(Mutations.SET_UIFORM_INFO, data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.GET_UIFORM_SELECT](store) {
    return ApiService.get(`/admin/uiform/select`)
      .then(({ data }) => {
        store.commit(Mutations.SET_UIFORM_SELECT, data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.GET_UIFORM_CREATE](store, payload) {
    return ApiService.get(`/admin/uiform/label/${payload}`, payload.data)
      .then(({ data }) => {
        store.commit(Mutations.SET_UIFORM_CREATE, data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.EDIT_UIFORM](store, payload) {
    return ApiService.put(`/admin/uiform`, payload)
      .then(() => {
        store.commit(Mutations.RESET_UIFORM)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.ADD_UIFORM](store, payload) {
    return ApiService.post(`/admin/uiform`, payload)
      .then(() => {
        store.commit(Mutations.RESET_UIFORM)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
}
