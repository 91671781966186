export class State {
  directions = {}
  directionSelect = {}
  estates = {}
  estateInfo = {}
  estateDetails = {}
  estateTypes = {}
  estateSEO = {}
  estateTags = {}
  estateTagsSearch = {}
  estateComments = {}
  estateCommentInfo = {}
  estateCommentChild = {}
  estateReports = {}
  estateCommentAlls = {}
}
