import ApiService from "@/core/services/ApiService"
import { ActionTree } from "vuex"
import { Actions, Mutations } from "@/store/enums/StoreEnums"
import { State } from "./state"

export default <ActionTree<State, any>>{
  [Actions.GET_FEEDBACKS](store, payload) {
    return ApiService.query(`/admin/feedbacks`, { params: payload })
      .then(({ data }) => {
        store.commit(Mutations.SET_FEEDBACKS, data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.GET_FEEDBACK_INFO](store, payload) {
    return ApiService.get(
      `/admin/feedback/${payload.feedBackTargetId}/${payload.feedBackTargetType}`
    )
      .then(({ data }) => {
        console.log(data.resultObj)
        store.commit(Mutations.SET_FEEDBACK_INFO, data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.HANDLE_FEEDBACKS](store, payload) {
    return ApiService.post(`/admin/feedback/handle`, payload)
      .then(({ data }) => {
        console.log(data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.ACCEPT_FEEDBACKS](store, payload) {
    return ApiService.patchData(`/admin/feedback/accept/${payload}`)
      .then(({ data }) => {
        console.log(data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.DELETE_FEEDBACK](store, payload) {
    return ApiService.delete(`/admin/feedback/${payload}`)
      .then(() => {
        store.commit(Mutations.RESET_ERROR)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
}
