import LayoutConfigTypes from "@/core/config/LayoutConfigTypes"

const config: LayoutConfigTypes = {
  themeName: "Metronic",
  themeVersion: "8.0.36",
  demo: "Admin BDS NET",
  main: {
    type: "default",
    primaryColor: "#009EF7",
    logo: {
      dark: "logo.png",
      light: "logo.png"
    }
  },
  illustrations: {
    set: "sketchy-1"
  },
  loader: {
    logo: "logo.png",
    display: true,
    type: "default"
  },
  scrollTop: {
    display: true
  },
  header: {
    display: true,
    menuIcon: "font",
    width: "fluid",
    fixed: {
      desktop: false,
      tabletAndMobile: false
    }
  },
  toolbar: {
    display: true,
    width: "fluid",
    fixed: {
      desktop: true,
      tabletAndMobile: true
    }
  },
  aside: {
    display: true,
    theme: "dark",
    fixed: true,
    menuIcon: "svg",
    minimized: false,
    minimize: true,
    hoverable: true
  },
  content: {
    width: "fixed"
  },
  footer: {
    width: "fluid"
  }
}

export default config
