import { GetterTree } from "vuex"
import { State } from "./state"

export default <GetterTree<State, any>>{
  currentPriceVips(state: State) {
    return state.priceVips
  },
  currentPriceVipInfo(state: State) {
    return state.priceVipInfo
  },
  currentPriceVipTypes(state: State) {
    return state.priceVipTypes
  },
  currentPriceVipProvinces(state: State) {
    return state.priceVipProvinces
  },
  currentPriceVipDistricts(state: State) {
    return state.priceVipDistricts
  }
}
