enum Permission {
  PERMISSION_SETTING_VIEW = "Permissions.CauHinh.View",
  PERMISSION_SETTING_EDIT = "Permissions.CauHinh.Edit",
  PERMISSION_SETTING_CREATE = "Permissions.CauHinh.Create",
  PERMISSION_SETTING_DELETE = "Permissions.CauHinh.Delete",

  PERMISSION_ROLE_VIEW = "Permissions.PhanQuyen.View",
  PERMISSION_ROLE_EDIT = "Permissions.PhanQuyen.Create",
  PERMISSION_ROLE_CREATE = "Permissions.PhanQuyen.Create",
  PERMISSION_ROLE_DELETE = "Permissions.PhanQuyen.Delete",
  PERMISSION_ROLE_CHANGEPASSWORD = "Permissions.PhanQuyen.ChangePassword",

  PERMISSION_ESTATE_VIEW = "Permissions.BaiVietBatDongSan.View",
  PERMISSION_ESTATE_EDIT = "Permissions.BaiVietBatDongSan.Edit",
  PERMISSION_ESTATE_CREATE = "Permissions.BaiVietBatDongSan.Create",
  PERMISSION_ESTATE_DELETE = "Permissions.BaiVietBatDongSan.Delete",
  PERMISSION_ESTATE_AWAITEDIT = "Permissions.BaiVietBatDongSan.AwaitEdit",
  PERMISSION_ESTATE_UPVIP = "Permissions.BaiVietBatDongSan.UpVip",
  PERMISSION_ESTATE_SEO = "Permissions.BaiVietBatDongSan.Seo",

  PERMISSION_LOCATION_VIEW = "Permissions.DiaDiem.View",
  PERMISSION_LOCATION_EDIT = "Permissions.DiaDiem.Edit",
  PERMISSION_LOCATION_CREATE = "Permissions.DiaDiem.Create",
  PERMISSION_LOCATION_DELETE = "Permissions.DiaDiem.Delete",

  PERMISSION_UI_VIEW = "Permissions.GiaoDienNguoiDung.View",
  PERMISSION_UI_EDIT = "Permissions.GiaoDienNguoiDung.Edit",
  PERMISSION_UI_CREATE = "Permissions.GiaoDienNguoiDung.Create",
  PERMISSION_UI_DELETE = "Permissions.GiaoDienNguoiDung.Delete",

  PERMISSION_ESTATEFORM_VIEW = "Permissions.HinhThucBatDongSan.View",
  PERMISSION_ESTATEFORM_EDIT = "Permissions.HinhThucBatDongSan.Edit",
  PERMISSION_ESTATEFORM_CREATE = "Permissions.HinhThucBatDongSan.Create",
  PERMISSION_ESTATEFORM_DELETE = "Permissions.HinhThucBatDongSan.Delete",

  PERMISSION_ESTATETYPES_VIEW = "Permissions.LoaiHinhBatDongSan.View",
  PERMISSION_ESTATETYPES_EDIT = "Permissions.LoaiHinhBatDongSan.Edit",
  PERMISSION_ESTATETYPES_CREATE = "Permissions.LoaiHinhBatDongSan.Create",
  PERMISSION_ESTATETYPES_DELETE = "Permissions.LoaiHinhBatDongSan.Delete",

  PERMISSION_TAGS_VIEW = "Permissions.TagBaiViet.View",
  PERMISSION_TAGS_EDIT = "Permissions.TagBaiViet.Edit",
  PERMISSION_TAGS_CREATE = "Permissions.TagBaiViet.Create",
  PERMISSION_TAGS_DELETE = "Permissions.TagBaiViet.Delete",
  PERMISSION_TAGS_SEO = "Permissions.TagBaiViet.Seo",

  PERMISSION_SEOLINK_VIEW = "Permissions.SeoLink.View",
  PERMISSION_SEOLINK_EDIT = "Permissions.SeoLink.Edit",
  PERMISSION_SEOLINK_CREATE = "Permissions.SeoLink.Create",
  PERMISSION_SEOLINK_DELETE = "Permissions.SeoLink.Delete",

  PERMISSION_TRAVEL_VIEW = "Permissions.DuLich.View",
  PERMISSION_TRAVEL_EDIT = "Permissions.DuLich.Edit",
  PERMISSION_TRAVEL_CREATE = "Permissions.DuLich.Create",
  PERMISSION_TRAVEL_DELETE = "Permissions.DuLich.Delete",
  PERMISSION_TRAVEL_REPORT = "Permissions.DuLich.Report",
  PERMISSION_TRAVEL_UPVIP = "Permissions.DuLich.UpVip",
  PERMISSION_TRAVEL_SEO = "Permissions.DuLich.Seo",

  PERMISSION_TRAVELTYPES_VIEW = "Permissions.LoaiHinhDuLich.View",
  PERMISSION_TRAVELTYPES_EDIT = "Permissions.LoaiHinhDuLich.Edit",
  PERMISSION_TRAVELTYPES_CREATE = "Permissions.LoaiHinhDuLich.Create",
  PERMISSION_TRAVELTYPES_DELETE = "Permissions.LoaiHinhDuLich.Delete",

  PERMISSION_TRAVELTAGS_VIEW = "Permissions.TagDuLich.View",
  PERMISSION_TRAVELTAGS_EDIT = "Permissions.TagDuLich.Edit",
  PERMISSION_TRAVELTAGS_CREATE = "Permissions.TagDuLich.Create",
  PERMISSION_TRAVELTAGS_DELETE = "Permissions.TagDuLich.Delete",
  PERMISSION_TRAVELTAGS_SEO = "Permissions.TagDuLich.Seo",

  PERMISSION_COMMENT_VIEW = "Permissions.Comment.View",
  PERMISSION_COMMENT_EDIT = "Permissions.Comment.Edit",
  PERMISSION_COMMENT_DELETE = "Permissions.Comment.Delete",
  PERMISSION_COMMENT_KEYWORD_DENIED = "Permissions.Comment.KeywordDenied",

  PERMISSION_BANNER_CREATE = "Permissions.Banner.Create",
  PERMISSION_BANNER_VIEW = "Permissions.Banner.View",
  PERMISSION_BANNER_EDIT = "Permissions.Banner.Edit",
  PERMISSION_BANNER_DELETE = "Permissions.Banner.Delete",

  PERMISSION_ACCOUNTUSER_CREATE = "Permissions.User.Create",
  PERMISSION_ACCOUNTUSER_VIEW = "Permissions.User.View",
  PERMISSION_ACCOUNTUSER_EDIT = "Permissions.User.Edit",
  PERMISSION_ACCOUNTUSER_DELETE = "Permissions.User.Delete",
  PERMISSION_ACCOUNTUSER_HDV = "Permissions.User.HDV",
  PERMISSION_ACCOUNTUSER_URLDENIED = "Permissions.User.URLDenied",
  PERMISSION_ACCOUNTUSER_INTEREST = "Permissions.User.Interests",
  PERMISSION_ACCOUNTUSER_DONATION = "Permissions.User.Donation",

  PERMISSION_BOOKINGBUDDY_VIEW = "Permissions.BookingBuddy.View",

  PERMISSION_PRICEVIP_VIEW = "Permissions.PriceVip.View",
  PERMISSION_PRICEVIP_EDIT = "Permissions.PriceVip.Edit",
  PERMISSION_PRICEVIP_CREATE = "Permissions.PriceVip.Create",

  PERMISSION_PRICEPOST_VIEW = "Permissions.PricePost.View",
  PERMISSION_PRICEPOST_EDIT = "Permissions.PricePost.Edit",
  PERMISSION_PRICEPOST_CREATE = "Permissions.PricePost.Create",

  PERMISSION_USERMANUAL_VIEW = "Permissions.HuongDanSuDung.View",
  PERMISSION_USERMANUAL_EDIT = "Permissions.HuongDanSuDung.Edit",
  PERMISSION_USERMANUAL_CREATE = "Permissions.HuongDanSuDung.Create",
  PERMISSION_USERMANUAL_DELETE = "Permissions.HuongDanSuDung.Delete",

  PERMISSION_CATEGORYARTICLE_VIEW = "Permissions.DanhMucTinTuc.View",
  PERMISSION_CATEGORYARTICLE_EDIT = "Permissions.DanhMucTinTuc.Edit",
  PERMISSION_CATEGORYARTICLE_CREATE = "Permissions.DanhMucTinTuc.Create",
  PERMISSION_CATEGORYARTICLE_DELETE = "Permissions.DanhMucTinTuc.Delete",

  PERMISSION_ARTICLE_VIEW = "Permissions.TinTuc.View",
  PERMISSION_ARTICLE_EDIT = "Permissions.TinTuc.Edit",
  PERMISSION_ARTICLE_CREATE = "Permissions.TinTuc.Create",
  PERMISSION_ARTICLE_DELETE = "Permissions.TinTuc.Delete",

  PERMISSION_MEDIA_VIEW = "Permissions.Media.View",
  PERMISSION_MEDIA_EDIT = "Permissions.Media.Edit",
  PERMISSION_MEDIA_CREATE = "Permissions.Media.Create",
  PERMISSION_MEDIA_DELETE = "Permissions.Media.Delete",

  PERMISSION_COMMITMENTPACK_VIEW = "Permissions.GoiCamKet.View",
  PERMISSION_COMMITMENTPACK_EDIT = "Permissions.GoiCamKet.Edit",
  PERMISSION_COMMITMENTPACK_CREATE = "Permissions.GoiCamKet.Create",
  PERMISSION_COMMITMENTPACK_DELETE = "Permissions.GoiCamKet.Delete",

  PERMISSION_REPORT_VIEW = "Permissions.BaoCao.View",
  PERMISSION_REPORT_ACCOUNT = "Permissions.BaoCao.TaiKhoan",
  PERMISSION_REPORT_POSTS = "Permissions.BaoCao.DangTin",
  PERMISSION_REPORT_POSTVIP = "Permissions.BaoCao.TinVIP",

  PERMISSION_FEEDBACK_VIEW = "Permissions.GopY.View",
  PERMISSION_FEEDBACK_DELETE = "Permissions.GopY.Delete"
}

export { Permission }
