import ApiService from "@/core/services/ApiService"
import { ActionTree } from "vuex"
import { Actions, Mutations } from "@/store/enums/StoreEnums"
import { State } from "./state"
export default <ActionTree<State, any>>{
  [Actions.GET_DASHBOARD](store) {
    return ApiService.get(`/admin/dashboard`)
      .then(({ data }) => {
        store.commit(Mutations.SET_DASHBOARD, data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.GET_DASHBOARD_TURNOVER](store, payload) {
    return ApiService.query(`/admin/dashboard/turnover`, { params: payload })
      .then(({ data }) => {
        store.commit(Mutations.SET_DASHBOARD_TURNOVER, data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.GET_DASHBOARD_TURNOVER_CITY](store, payload) {
    return ApiService.query(`/admin/dashboard/turnover`, { params: payload })
      .then(({ data }) => {
        store.commit(Mutations.SET_DASHBOARD_TURNOVER_CITY, data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.GET_DASHBOARD_TURNOVER_TOTAL](store, payload) {
    return ApiService.query(`/admin/dashboard/turnover-total`, {
      params: payload
    })
      .then(({ data }) => {
        store.commit(Mutations.SET_DASHBOARD_TURNOVER_TOTAL, data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
}
