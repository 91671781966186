import { GetterTree } from "vuex"
import { State } from "./state"

export default <GetterTree<State, any>>{
  currentBanners(state: State) {
    return state.banners
  },
  currentBannerInfo(state: State) {
    return state.bannerInfo
  }
}
