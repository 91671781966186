import { GetterTree } from "vuex"
import { State } from "./state"
import { User } from "../../../interfaces/Permission"
import { ValidationError } from "../../../interfaces/IApiResult"

export default <GetterTree<State, any>>{
  currentUser(state: State): User {
    return state.user
  },

  isUserAuthenticated(state: State): boolean {
    return state.isAuthenticated
  },

  getErrors(state: State): ValidationError[] {
    return state.errors
  }
}
