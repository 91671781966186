import { GetterTree } from "vuex"
import { State } from "./state"
import objectPath from "object-path"

export default <GetterTree<State, any>>{
  layoutConfig(state) {
    return (path, defaultValue) => {
      return objectPath.get(state.config, path, defaultValue)
    }
  }
}
