import { MutationTree } from "vuex"
import { Mutations } from "@/store/enums/StoreEnums"
import { State } from "./state"

export default <MutationTree<State>>{
  [Mutations.SET_BOOKINGS](state, payload) {
    state.bookings = payload
  },
  [Mutations.SET_BOOKING_INFO](state, payload) {
    state.bookingInfo = payload
  },
  [Mutations.SET_BOOKING_BID](state, payload) {
    state.bookingBid = payload
  },
  [Mutations.SET_BOOKING_REPORTS](state, payload) {
    state.bookingReports = payload
  },
  [Mutations.SET_BOOKING_VIOLATIVE](state, payload) {
    state.bookingViolative = payload
  }
}
