import { MutationTree } from "vuex"
import { Mutations } from "@/store/enums/StoreEnums"
import { State } from "./state"

export default <MutationTree<State>>{
  [Mutations.SET_FEEDBACKS](state, payload) {
    state.feedbacks = payload
  },
  [Mutations.SET_FEEDBACK_INFO](state, payload) {
    state.feedbackInfo = payload
  }
}
