import { TagType } from "../../../interfaces/ITag"

interface TagInfo {
  tagTypes: TagType[]
}

export class State implements TagInfo {
  tagTypes = [] as TagType[]
  tags = {}
  tagInfo = {}
}
