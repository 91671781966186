import { GetterTree } from "vuex"
import { State } from "./state"

export default <GetterTree<State, any>>{
  currentDashboards(state: State) {
    return state.dashboard
  },
  currentDashboardTurnover(state: State) {
    return state.dashboardTurnover
  },
  currentDashboardTurnoverTotal(state: State) {
    return state.dashboardTurnoverTotal
  },
  currentDashboardTurnoverCity(state: State) {
    return state.dashboardTurnoverCity
  }
}
