import { createApp } from "vue"
import App from "./App.vue"

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router"
import store from "./store"
import ElementPlus from "element-plus"
import i18n from "@/core/plugins/i18n"

//imports for app initialization
import ApiService from "@/core/services/ApiService"
import { initApexCharts } from "@/core/plugins/apexcharts"
import { initInlineSvg } from "@/core/plugins/inline-svg"
import { initVeeValidate } from "@/core/plugins/vee-validate"

import JoditEditor from "jodit-vue"
import "jodit/build/jodit.min.css"

import "@/core/plugins/prismjs"
import "bootstrap"

import VueDOMPurifyHTML from "vue-dompurify-html"

const app = createApp(App)
app.use(JoditEditor)
app.use(store)
app.use(router)
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: Unreachable code error
app.use(ElementPlus)

app.use(VueDOMPurifyHTML)

ApiService.init(app)
initApexCharts(app)
initInlineSvg(app)
initVeeValidate()

app.use(i18n)

app.mount("#app")
