import { GetterTree } from "vuex"
import { State } from "./state"

export default <GetterTree<State, any>>{
  currentComments(state: State) {
    return state.comments
  },
  currentCommentChilds(state: State) {
    return state.commentChilds
  },
  currentCommentTypes(state: State) {
    return state.commentTypes
  },
  currentCommentInfos(state: State) {
    return state.commentInfos
  }
}
