import { MutationTree } from "vuex"
import { Mutations } from "@/store/enums/StoreEnums"
import { State } from "./state"

export default <MutationTree<State>>{
  [Mutations.SET_TOURGUIDES](state, payload) {
    state.tourGuides = payload
  },
  [Mutations.SET_TOURGUIDE_INFO](state, payload) {
    state.tourGuideInfo = payload
  },
  [Mutations.SET_TOURGUIDE_PENDING](state, payload) {
    state.tourGuidePending = payload
  }
}
