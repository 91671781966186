import { GetterTree } from "vuex"
import { State } from "./state"

export default <GetterTree<State, any>>{
  currentPricePosts(state: State) {
    return state.pricePosts
  },
  currentPricePostInfo(state: State) {
    return state.pricePostInfo
  },
  currentPricePostDuration(state: State) {
    return state.pricePostDurations
  },
  currentPricePostProvinces(state: State) {
    return state.pricePostProvinces
  },
  currentPricePostDistricts(state: State) {
    return state.pricePostDistricts
  }
}
