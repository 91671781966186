import { MutationTree } from "vuex"
import { Mutations } from "@/store/enums/StoreEnums"
import { State } from "./state"
import { ValidationError } from "../../../interfaces/IApiResult"
import JwtService from "@/core/services/JwtService"
import { User } from "@/interfaces/Permission"

export default <MutationTree<State>>{
  [Mutations.RESET_ERROR](state) {
    state.errors = {} as ValidationError[]
  },

  [Mutations.SET_ERROR](state, error: ValidationError[]) {
    state.errors = { ...error }
  },

  [Mutations.SET_AUTH](state, token) {
    state.isAuthenticated = true
    // this.user = user;
    state.errors = {} as ValidationError[]
    JwtService.saveToken(token)
  },

  [Mutations.SET_USER](state, user) {
    state.user = user
    JwtService.saveRoles(user.permissions)
  },

  // @Mutation
  // [Mutations.SET_PASSWORD](password) {
  //   //this.user.password = password;
  // }

  [Mutations.PURGE_AUTH](state) {
    state.isAuthenticated = false
    state.user = {} as User
    state.errors = {} as ValidationError[]
    JwtService.destroyToken()
    JwtService.destroyRoles()
  }
}
