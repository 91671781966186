import { GetterTree } from "vuex"
import { State } from "./state"

export default <GetterTree<State, any>>{
  currentTypes(state: State) {
    return state.types
  },
  currentTypeInfo(state: State) {
    return state.typeInfo
  },
  currentTypeSelect(state: State) {
    return state.typeParents
  },
  currentTypeSort(state: State) {
    return state.typeSort
  },
  currentTypeIcon(state: State) {
    return state.typeIcon
  }
}
