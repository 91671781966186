import { MutationTree } from "vuex"
import { Mutations } from "@/store/enums/StoreEnums"
import { State } from "./state"

export default <MutationTree<State>>{
  [Mutations.SET_COMMENTS](state, payload) {
    state.comments = payload
  },
  [Mutations.SET_COMMENT_CHILD](state, payload) {
    state.commentChilds = payload
  },
  [Mutations.SET_COMMENT_TYPE](state, payload) {
    state.commentTypes = payload
  },
  [Mutations.SET_COMMENT_INFO](state, payload) {
    state.commentInfos = payload
  }
}
