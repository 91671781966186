import ApiService from "@/core/services/ApiService"
import { ActionTree } from "vuex"
import { Actions, Mutations } from "@/store/enums/StoreEnums"
import { State } from "./state"
export default <ActionTree<State, any>>{
  [Actions.GET_WATERMARKS](store, pageIndex = 1) {
    return ApiService.query(`/admin/watermarks`, {
      params: { page: pageIndex, pageSize: 30 }
    })
      .then(({ data }) => {
        store.commit(Mutations.SET_WATERMARKS, data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.GET_WATERMARK_INFO](store, payload) {
    return ApiService.get(`/admin/watermark/${payload}`)
      .then(({ data }) => {
        store.commit(Mutations.SET_WATERMARK_INFO, data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.GET_WATERMARK_SELECT](store) {
    return ApiService.get(`/admin/watermark/select`)
      .then(({ data }) => {
        store.commit(Mutations.SET_WATERMARK_SELECT, data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.EDIT_WATERMARK](store, payload) {
    return ApiService.put(`/admin/watermark/${payload.id}`, payload.data)
      .then(() => {
        store.commit(Mutations.RESET_ERROR)
        store.commit(Mutations.RESET_WATERMARK_INFO)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.ADD_WATERMARK](store, payload) {
    return ApiService.post(`/admin/watermark`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR)
        store.commit(Mutations.RESET_WATERMARK_INFO)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
}
