import ApiService from "@/core/services/ApiService"
import { Actions, Mutations } from "@/store/enums/StoreEnums"
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators"
import { ValidationError } from "../../interfaces/IApiResult"
import { MenuParentModel, MenuModel } from "../../interfaces/Config"

@Module
export default class MenuAppModule extends VuexModule {
  errors = {} as ValidationError[]
  menuAppParents = {} as MenuParentModel[]
  menuApp = {} as MenuModel[]
  menuAppInfo = {}
  menuAppSort = {}
  menuAppIcon = {}
  /**
   * Get getMenuAppParent
   * @returns MenuParentModel[]
   */
  get getMenuAppParent(): MenuParentModel[] {
    return this.menuAppParents
  }
  /**
   * Get getMenuApp
   * @returns MenuModel[]
   */
  get currentMenuApp(): MenuModel[] {
    return this.menuApp
  }
  get currentChildMenuApp(): MenuModel[] {
    return this.menuApp
  }
  /**
   * Get MenuAppInfo
   * @returns menuAppInfo
   */
  get currentMenuAppInfo() {
    return this.menuAppInfo
  }
  /**
   * Get menuAppInfo
   * @returns menuAppSort
   */
  get currentMenuAppSort() {
    return this.menuAppSort
  }
  /**
   * Get menuAppIcon
   * @returns menuAppIcon
   */
  get currentMenuAppIcon() {
    return this.menuAppIcon
  }
  @Mutation
  [Mutations.SET_MENU_APP_PARENTS](payload): void {
    this.menuAppParents = payload
  }
  @Mutation
  [Mutations.SET_MENU_APPS](payload): void {
    this.menuApp = payload
  }
  @Mutation
  [Mutations.SET_MENU_APP_INFO](payload): void {
    this.menuAppInfo = payload
  }
  @Mutation
  [Mutations.RESET_MENU_APP_INFO](): void {
    this.menuAppInfo = {}
  }
  @Mutation
  [Mutations.SET_MENU_APP_SORT](payload): void {
    this.menuAppSort = payload
  }
  @Mutation
  [Mutations.RESET_MENU_APP_SORT](): void {
    this.menuAppSort = {}
  }
  @Mutation
  [Mutations.SET_ICON_MENU_APP](payload): void {
    this.menuAppIcon = payload
  }
  @Mutation
  [Mutations.SET_CHILD_MENU_APP](payload): void {
    this.menuApp = payload
  }
  @Action
  [Actions.GET_MENU_APP_PARENTS]() {
    return ApiService.get(`/admin/MenuApp/parents`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_MENU_APP_PARENTS, data.resultObj)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
  @Action
  [Actions.ADD_MENU_APP](menuApp) {
    return ApiService.post(`/admin/MenuApp`, menuApp)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
  @Action
  [Actions.GET_MENU_APPS]() {
    return ApiService.get(`/admin/MenuApp/menus`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_MENU_APPS, data.resultObj)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
  @Action
  [Actions.GET_MENU_APP_INFO](payload) {
    return ApiService.get(`/admin/MenuApp/${payload}`)
      .then(({ data }) => {
        console.log(data.resultObj)
        this.context.commit(Mutations.SET_MENU_APP_INFO, data.resultObj)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
  @Action
  [Actions.EDIT_MENU_APP](payload) {
    return ApiService.put(`/admin/MenuApp/${payload.id}`, payload)
      .then(({ data }) => {
        console.log(data.resultObj)
        this.context.commit(Mutations.RESET_MENU_APP_INFO)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
  @Action
  [Actions.DELETE_MENU_APP](payload) {
    return ApiService.delete(`/admin/MenuApp/${payload}`)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
  @Action
  [Actions.GET_MENU_APP_SORT](payload) {
    return ApiService.get(`/admin/MenuApp/sort/${payload}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_MENU_APP_SORT, data.resultObj)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
  @Action
  [Actions.EDIT_MENU_APP_SORT](payload) {
    return ApiService.patch(`/admin/MenuApp/sort`, payload)
      .then(() => {
        this.context.commit(Mutations.RESET_MENU_APP_SORT)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
  @Action
  [Actions.GET_ICON_MENU_APP](payload) {
    return ApiService.get(`/admin/menu/icon/${payload}`)
      .then(({ data }) => {
        console.log(data.resultObj)
        this.context.commit(Mutations.SET_ICON_MENU_APP, data.resultObj)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
  @Action
  [Actions.EDIT_ICON_MENU_APP](payload) {
    return ApiService.patch(`/admin/menu/icon/${payload.id}`, payload.data)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
  @Action
  [Actions.UPDATE_MENU_APP_SORT](payload) {
    this.context.commit(Mutations.SET_MENU_APP_SORT, payload)
  }
  @Action
  [Actions.GET_CHILD_MENU_APP](id) {
    return ApiService.get(`/admin/MenuApp/child/menus/${id}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CHILD_MENU_APP, data.resultObj)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
}
