import ApiService from "@/core/services/ApiService"
import { Actions, Mutations } from "@/store/enums/StoreEnums"
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators"
import { IWard, WardSelect } from "@/interfaces/IWard"
import { ResultObj } from "@/interfaces/IApiResult"

@Module
export default class WardModule extends VuexModule {
  wards = {} as ResultObj<IWard>
  wardInfo = {} as IWard
  wardSelect = {} as WardSelect[]
  /**
   * Get wards
   * @returns IWard[]
   */
  get currentWards(): ResultObj<IWard> {
    return this.wards
  }
  /**
   * Get wards Select
   * @returns WardSelect[]
   */
  get getWardSeleted(): WardSelect[] {
    return this.wardSelect
  }
  /**
   * Get wards Info
   * @returns IWard
   */
  get currentWardInfo(): IWard {
    return this.wardInfo
  }

  @Mutation
  [Mutations.SET_WARDS](payload): void {
    this.wards = payload
  }
  @Mutation
  [Mutations.SET_WARD_SELECT](payload): void {
    this.wardSelect = payload
  }
  @Mutation
  [Mutations.SET_WARD_INFO](payload): void {
    this.wardInfo = payload
  }
  @Mutation
  [Mutations.RESET_WARDS](): void {
    this.wards = {} as ResultObj<IWard>
    this.wardInfo = {} as IWard
    this.wardSelect = {} as WardSelect[]
  }
  @Mutation
  [Mutations.DELETE_DISTRICT_SELECT](): void {
    this.wardSelect = {} as WardSelect[]
  }
  @Action
  [Actions.GET_WARDS](payload) {
    return ApiService.query(`/admin/wards`, { params: payload })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_WARDS, data.resultObj)
      })
      .catch(({ response }) => {
        console.log(response)
      })
  }
  @Action
  [Actions.GET_WARD_INFO](payload) {
    return ApiService.get(`/admin/ward/${payload}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_WARD_INFO, data.resultObj)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
  @Action
  [Actions.RESET_WARD_INFO](payload) {
    this.context.commit(Mutations.SET_WARD_INFO, null)
  }
  @Action
  [Actions.GET_WARD_SELECT](payload) {
    return ApiService.get(`/admin/ward/select/${payload}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_WARD_SELECT, data.resultObj)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }

  @Action
  [Actions.ADD_WARD](payload) {
    return ApiService.post(`/admin/ward`, payload)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR)
        //this.context.commit(Mutations.RESET_COUNTRY_INFO);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }

  @Action
  [Actions.EDIT_WARD](payload) {
    return ApiService.put(`/admin/ward/${payload.id}`, payload)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR)
        //this.context.commit(Mutations.RESET_COUNTRY_INFO);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
  @Action
  [Actions.DELETE_WARD](payload) {
    return ApiService.delete(`/admin/ward/${payload}`)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR)
        // this.context.commit(Mutations.RESET_PROVINCE);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
  @Action
  [Actions.RESET_DISTRICT_SELECT]() {
    this.context.commit(Mutations.DELETE_DISTRICT_SELECT)
  }
  @Action
  [Actions.RESET_WARD_SELECT]() {
    this.context.commit(Mutations.SET_WARD_SELECT, {})
  }
}
