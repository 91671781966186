import { MutationTree } from "vuex"
import { Mutations } from "@/store/enums/StoreEnums"
import { State } from "./state"

export default <MutationTree<State>>{
  [Mutations.SET_TAG_TYPE](state, tagTypes) {
    state.tagTypes = tagTypes
  },
  [Mutations.SET_TAGS](state, payload) {
    state.tags = payload
  },
  [Mutations.SET_TAG_INFO](state, payload) {
    state.tagInfo = payload
  },
  [Mutations.RESET_TAG_INFO](state) {
    state.tagInfo = {}
  }
}
