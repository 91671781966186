import { GetterTree } from "vuex"
import { State } from "./state"

export default <GetterTree<State, any>>{
  currentReportAccount(state: State) {
    return state.reportAccount
  },
  currentReportPosts(state: State) {
    return state.reportPosts
  },
  currentReportPostsVip(state: State) {
    return state.reportPostVip
  },
  currentReportPostsInfo(state: State) {
    return state.reportPostsInfo
  },
  currentReportPostsVipInfo(state: State) {
    return state.reportPostsVipInfo
  }
}
