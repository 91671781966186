import { MutationTree } from "vuex"
import { Mutations } from "@/store/enums/StoreEnums"
import { State } from "./state"
import { UIFormInfo, UIFormCreate, UIFormSelect } from "@/interfaces/IUIForm"

export default <MutationTree<State>>{
  [Mutations.SET_UIFORMS](state, payload) {
    state.uiforms = payload
  },
  [Mutations.SET_UIFORM_INFO](state, payload) {
    state.uiformInfo = payload
  },
  [Mutations.SET_UIFORM_SELECT](state, payload) {
    state.uiformSelect = payload
  },
  [Mutations.SET_UIFORM_CREATE](state, payload) {
    state.uiformCreate = payload
  },
  [Mutations.RESET_UIFORM](state) {
    state.uiformInfo = {} as UIFormInfo
    state.uiformCreate = {} as UIFormCreate[]
    state.uiformSelect = {} as UIFormSelect[]
  }
}
