enum Actions {
  // config
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  //article
  SET_ARTICLE_QUERY = "SET_ARTICLE_QUERY",
  //commit pack
  SET_COMMITMENT_PACK_QUERY = "SET_COMMITMENT_PACK_QUERY",
  // media
  OPEN_RENAME_FOLDER_FORM = "OPEN_RENAME_FOLDER_FORM",
  CLOSE_RENAME_FOLDER_FORM = "CLOSE_RENAME_FOLDER_FORM",
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  //role types
  ADD_NEW_ROLE = "addNewRole",
  GET_ALL_ROLES = "getAllRoles",
  EDIT_ROLE = "editRole",
  CHANGE_PASSWORD = "changePassword",
  DELETE_ROLE = "deleteRole",
  GET_PERMISSION = "getPermissions",
  UPDATE_PERMISSION = "updatePermission",
  ADD_USER_ADMIN = "addUserAdmin",
  GET_ROLE_SELECT = "getRoleSelect",
  GET_USERS_ADMIN = "getUsersAdmin",
  GET_USER_ADMIN = "getUserAdmin",
  EDIT_USER_ADMIN = "editUserAdmin",
  GET_SETTING = "getSetting",
  EDIT_SETTING = "editSetting",
  //menuapps
  GET_MENU_APP_PARENTS = "getMenuAppParents",
  ADD_MENU_APP = "addMenuApp",
  GET_MENU_APPS = "getMenuApps",
  GET_MENU_APP_INFO = "getMenuAppInfo",
  EDIT_MENU_APP = "editMenuApp",
  DELETE_MENU_APP = "deleteMenuApp",
  GET_MENU_APP_SORT = "getMenuAppSort",
  EDIT_MENU_APP_SORT = "editMenuAppSort",
  GET_ICON_MENU_APP = "getIconMenuApp",
  EDIT_ICON_MENU_APP = "editIconMenuApp",
  UPDATE_MENU_APP_SORT = "updateMenuAppSort",
  GET_CHILD_MENU_APP = "getChildMenuApp",
  //menu
  GET_MENU_PARENTS = "getMenuParents",
  GET_MENU_PROFILE_PARENTS = "getMenuProfileParents",
  ADD_MENU = "addMenu",
  GET_MENUS = "getMenus",
  GET_MENU_HOMEPAGE = "getMenuHomepage",
  GET_MENU_SECTIONS = "getMenuSections",
  GET_MENU_ESTATE_TOPS = "getMenuEstateTops",
  GET_MENU_TRAVEL_TOPS = "getMenuTravelTops",
  GET_MENU_ESTATE_LOCATION_TOPS = "getMenuEstateLocationTops",
  GET_MENU_TRAVEL_LOCATION_TOPS = "getMenuTravelLocationTops",
  GET_MENU_INFO = "getMenuInfo",
  GET_MENU_PROFILE = "getMenuProfile",
  EDIT_MENU = "editMenu",
  DELETE_MENU = "deleteMenu",
  GET_MENU_SORT = "getMenuSort",
  EDIT_MENU_SORT = "editMenuSort",
  GET_ICON_MENU = "getIconMenu",
  EDIT_ICON_MENU = "editIconMenu",
  UPDATE_MENU_SORT = "updateMenuSort",
  //footer
  GET_FOOTER_PARENTS = "getFooterParents",
  ADD_FOOTER = "addFooter",
  GET_FOOTERS = "getFooters",
  GET_FOOTER_INFO = "getFooterInfo",
  EDIT_FOOTER = "editFooter",
  DELETE_FOOTER = "deleteFooter",
  GET_FOOTER_SORT = "getFooterSort",
  EDIT_FOOTER_SORT = "editFooterSort",
  GET_ICON_FOOTER = "getIconFooter",
  EDIT_ICON_FOOTER = "editIconFooter",
  UPDATE_FOOTER_SORT = "updateFooterSort",
  //staticPage
  GET_PAGES = "getPages",
  ADD_PAGE = "addPage",
  GET_PAGE_SELECT = "getPageSelect",
  GET_PAGE_INFO = "getPageInfo",
  GET_IMAGE_PAGE = "getImagePage",
  EDIT_PAGE = "editPage",
  EDIT_IMAGE_PAGE = "editImagePage",
  DELETE_PAGE = "deletePage",
  //country
  GET_COUNTRYS = "getCountrys",
  GET_COUNTRY_SELECT = "getCountrySelect",
  GET_COUNTRY_INFO = "getCountryInfo",
  ADD_COUNTRY = "addCountry",
  EDIT_COUNTRY = "editCountry",
  DELETE_COUNTRY = "deleteCountry",
  //provinces
  GET_PROVINCES = "getProvinces",
  GET_PROVINCE_SELECT = "getProvinceSelect",
  GET_PROVINCE_INFO = "getProvinceInfo",
  ADD_PROVINCE = "addProvince",
  EDIT_PROVINCE = "editProvince",
  DELETE_PROVINCE = "deleteProvince",
  GET_POSITION_BRANCH_PROVINCE = "getPositionBranchProvince",
  EDIT_POSITION_BRANCH_PROVINCE = "editPositionBranchProvince",
  RESET_POSITION = "resetPosition",
  GET_PROVINCE_INFO_BY_TYPE = "getProvinceInfoByType",
  UPDATE_PROVINCE_INFO_BY_ID = "updateProvinceInfoById",
  //district
  GET_DISTRICTS = "getDistricts",
  GET_DISTRICT_SELECT = "getDistrictSelect",
  GET_DISTRICT_INFO = "getDistrictInfo",
  GET_DISTRICT_MULTIPLE = "getDistrictMultiple",
  ADD_DISTRICT = "addDistrict",
  EDIT_DISTRICT = "editDistrict",
  DELETE_DISTRICT = "deleteDistrict",
  RESET_DISTRICT_SELECT = "resetDistrictSelect",
  RESET_DISTRICT_INFO = "resetDistrictInfo",
  //wards
  GET_WARDS = "getWards",
  GET_WARD_SELECT = "getWardSelect",
  GET_WARD_INFO = "getWardInfo",
  ADD_WARD = "addWard",
  EDIT_WARD = "editWard",
  DELETE_WARD = "deleteWard",
  RESET_WARD_SELECT = "resetWardSelect",
  RESET_WARD_INFO = "resetWardInfo",
  //seo link
  GET_SEOLINKS = "getSeolinks",
  GET_SEOLINK_INFO = "getSeolinkInfo",
  GET_IMAGE_SEOLINK = "getImageSeolink",
  EDIT_IMAGE_SEOLINK = "editImageSeolink",
  ADD_SEOLINK = "addSeolink",
  EDIT_SEOLINK = "editSEOLink",
  DELETE_SEOLINK = "deleteSeolink",
  //posistion
  GET_POSITION_PROVINCE = "getPositionProvider",
  GET_POSITION_DISTRICT = "getPositionDistrict",
  GET_POSITION_WARD = "getPositionWard",
  EDIT_POSITION_PROVINCE = "updatePositionProvider",
  EDIT_POSITION_DISTRICT = "updatePositionDistrict",
  EDIT_POSITION_WARD = "updatePositionWard",

  //tag
  GET_TAG_TYPES = "GET_TAG_TYPES",
  GET_TAGS = "getTags",
  GET_TAG_INFO = "getTagInfo",
  ADD_TAG = "addTag",
  ADD_TAG_NEW = "addTagNew",
  EDIT_TAG = "editTag",
  EDIT_TAG_NEW = "editTagNew",
  DELETE_TAG = "deleteTag",
  //UI Form
  GET_UIFORMS = "getUiForms",
  GET_UIFORM_INFO = "getUiformInfo",
  GET_UIFORM_SELECT = "getUiformSelect",
  GET_UIFORM_CREATE = "getUiformCreate",
  ADD_UIFORM = "addUiform",
  EDIT_UIFORM = "editUiform",
  //UI Section
  GET_UISECTIONS = "getUisections",
  GET_UISECTION_INFO = "getUisectionInfo",
  GET_UISECTION_PAGE = "getUisectionPage",
  GET_UISECTION_OF_PAGE = "getUisectionOfPage",
  GET_UISECTION_IMAGE = "getUisectionImage",
  GET_UISECTION_SORT = "getUisectionSort",
  ADD_UISECTION = "addUisection",
  EDIT_UISECTION = "editUisection",
  EDIT_UISECTION_IMAGE = "editUisectionImage",
  DELETE_SECTION = "deleteSection",
  EDIT_UISECTION_SORT = "editUisectionSort",
  UPDATE_UISECTION_SORT = "updateUisectionSort",
  //Watermark
  GET_WATERMARKS = "getWatermarks",
  GET_WATERMARK_INFO = "getWatermarkInfo",
  GET_WATERMARK_SELECT = "getWatermarkSelect",
  ADD_WATERMARK = "addWatermark",
  EDIT_WATERMARK = "editWatermark",
  //Estate-category Types
  GET_TYPES = "getTypes",
  GET_TYPE_INFO = "getTypeInfo",
  GET_TYPE_SORT = "getTypeSort",
  GET_TYPE_SELECT = "getTypeSelect",
  GET_TYPE_ICON = "getTypeIcon",
  ADD_TYPE = "addType",
  EDIT_TYPE = "editType",
  EDIT_TYPE_SORT = "editTypeSort",
  EDIT_TYPE_ICON = "editTypeIcon",
  DELETE_TYPE = "deleteType",
  UPDATE_TYPE_SORT = "updateTypeSort",
  //forms
  GET_FORMS = "getForms",
  GET_FORM_INFO = "getFormInfo",
  GET_FORM_SORT = "getFormSort",
  GET_FORM_SELECT = "getFormSelect",
  ADD_FORM = "addForm",
  EDIT_FORM = "editForm",
  EDIT_FORM_SORT = "editFormSort",
  UPDATE_FORM_SORT = "updateFormSort",
  DELETE_FORM = "deleteForm",
  //estate
  GET_DIRECTION = "getDirection",
  GET_ESTATES = "getEstates",
  GET_ESTATE_INFO = "getEstatesInfo",
  GET_DIRECTION_SELECT = "getDirectionsSelect",
  ADD_ESTATE = "addEstate",
  EDIT_ESTATE = "editEstate",
  GET_ESTATE_DETAILS = "getEstateDetails",
  GET_ESTATE_TYPES = "getEstateTypes",
  UPDATE_ESTATE_PUBLISHED = "updateEstatePublished",
  UPDATE_ESTATE_COMMENT = "updateEstateComment",
  UPDATE_ESTATE_PROJECT = "updateEstateProject",
  UPDATE_ESTATE_ROUTE = "updateEstateRoute",
  UPDATE_ESTATE_WARNING = "updateEstateWarning",
  UPDATE_ESTATE_APPROVED = "updateEstateApproved",
  UPDATE_ESTATE_VIOLATIVE = "updateEstateViolative",
  REJECT_ESTATE = "rejectEstate",
  UPDATE_ESTATE_EXPIRED = "updateEstateExpired",
  GET_ESTATE_SEO = "getEstateSeo",
  EDIT_ESTATE_SEO = "editEstateSeo",
  GET_ESTATE_TAGS = "getEstateTags",
  GET_ESTATE_TAGS_SEARCH = "getEstateTagsSearch",
  EDIT_ESTATE_TAG = "editEstateTag",
  GET_ESTATE_COMMENTS = "getEstateComments",
  GET_ESTATE_COMMENT_INFO = "getEstateCommentInfo",
  GET_ESTATE_COMMENT_CHILD = "getEstateCommentChild",
  APPROVED_ESTATE_COMMENT = "approvedEstateComment",
  DELETE_ESTATE_COMMENT = "deleteEstateComment",
  DELETE_ESTATE = "deleteEstate",
  DELETE_PERMANENTL_ESTATE = "deletePermanentlEstate",
  DELETE_ESTATE_REPORT = "deleteEstateReport",
  GET_ESTATE_REPORT = "getEstateReport",
  GET_ESTATES_PENDING = "getEstatesPending",
  GET_ESTATE_INFO_PENDING = "getEstateInfoPending",
  GET_ESTATE_DETAILS_PENDING = "getEstateDetailsPending",
  CANCEL_ESTATE_PENDING = "cancelEstatePending",
  EDIT_ESTATE_PENDING = "editEstatePending",
  GET_ESTATE_COMMENT_ALL = "getEstateCommentAll",
  BROWSE_ESTATE = "browseEstate",

  //Travel
  GET_TRAVELS = "getTravels",
  GET_TRAVEL_INFO = "getTravelInfo",
  ADD_TRAVEL = "addTravel",
  EDIT_TRAVEL = "editTravel",
  DELETE_TRAVEL = "deleteTravel",
  GET_TRAVEL_DETAILS = "getTravelDetails",
  GET_TRAVEL_TYPES = "getTravelTypes",
  GET_TRAVEL_TAGS_SEARCH = "getTravelTagsSearch",
  UPDATE_TRAVEL_PUBLISHED = "updateTravelPublished",
  UPDATE_TRAVEL_COMMENT = "updateTravelComment",
  GET_TRAVEL_SEO = "getTravelSeo",
  EDIT_TRAVEL_SEO = "editTravelSeo",
  EDIT_TRAVEL_TAG = "editTravelTag",
  GET_TRAVEL_TAGS = "getTravelTags",
  UPDATE_TRAVEL_EXPIRED = "updateTravelExpired",
  UPDATE_TRAVEL_PROJECT = "updateTravelProject",
  UPDATE_TRAVEL_ROUTE = "updateTravelRoute",
  UPDATE_TRAVEL_VIOLATIVE = "updateTravelViolative",
  GET_TRAVEL_REPORT = "getTravelReport",
  DELETE_TRAVEL_REPORT = "deleteTravelReport",
  //comment
  GET_COMMENTS = "getComments",
  GET_COMMENT_CHILD = "getCommentChild",
  APPROVED_COMMENT = "approvedComment",
  DELETE_COMMENT = "deleteComment",
  GET_COMMENT_NEWS = "getCommentNews",
  GET_COMMENT_TRAVEL = "getCommentTravel",
  GET_COMMENT_ESTATE = "getCommentEstate",
  GET_COMMENT_INFO = "getCommentInfo",
  //url banned
  GET_URL_BANNED = "getUrlBanned",
  ADD_KEYWORD_BANNED = "addKeywordBanned",
  DELETE_KEYWORD_BANNED = "deleteKeywordBanned",
  UPDATE_URL_BANNED = "updateUrlBanned",
  //keyword banned
  GET_KEYWORDS_BANNED = "getKeywordsBanned",
  ADD_KEYWORDS_BANNED = "addKeywordsBanned",
  DELETE_KEYWORDS_BANNED = "deleteKeywordsBanned",
  UPDATE_KEYWORDS_BANNED = "updateKeywordsBanned",
  //user account
  ADD_USER_ACCOUNT = "addUserAccount",
  GET_USER_ACCOUNTS = "getUserAccounts",
  GET_USER_ACCOUNT_INFO = "getUserAccountInfo",
  EDIT_USER_ACCOUNT = "editUserAccount",
  UPDATE_TRAVEL_REQUEST_USER_ACCOUNT = "updateTravelRequestUser",
  APPROVED_CODE_USER_ACCOUNT = "approvedCodeUserAccount",
  CANCELAPPROVED_CODE_USER_ACCOUNT = "cancelapprovedCodeUserAccount",
  UPDATE_CODE_USER_ACCOUNT = "updateCodeUserAccount",
  UPDATE_PASSWORD_USER_ACCOUNT = "updatePasswordUser",
  DELETE_USER_ACCOUNT = "deleteUserAccount",
  GET_USER_ACCOUNT_LOGS = "getUserAccountLogs",
  UPDATE_STATUS_USER_ACCOUNT = "updateStatusUserAccount",
  CANCEL_STATUS_USER_ACCOUNT = "cancelStatusUserAccount",
  DONATION_USER_ACCOUNT = "donationUserAccount",
  GET_USER_TRANSACTIONS = "getUserTransactions",

  //priceVip
  GET_PRICE_VIPS = "getPriceVips",
  GET_PRICE_VIP_INFO = "getPriceVipInfo",
  ADD_PRICE_VIP = "addPriceVip",
  EDIT_PRICE_VIP = "editPriceVip",
  GET_PRICE_VIP_TYPE = "getPriceVipType",
  GET_PRICE_VIP_PROVINCE = "getPriceVipProvince",
  EDIT_PRICE_VIP_PROVINCE = "editPriceVipProvince",
  GET_PRICE_VIP_DISTRICT = "getPriceVipDistrict",
  EDIT_PRICE_VIP_DISTRICT = "editPriceVipDistrict",
  RESET_PRICE_VIP_PROVINCE = "resetPriceVipProvince",
  RESET_PRICE_VIP_DISTRICT = "resetPriceVipDistrict",
  UPGRADE_VIP = "upgradeVip",
  //pricePost
  GET_PRICE_POSTS = "getPricePosts",
  GET_PRICE_POST_INFO = "getPricePostInfo",
  ADD_PRICE_POST = "addPricePost",
  EDIT_PRICE_POST = "editPricePost",
  GET_PRICE_POST_DURATION = "getPricePostDuration",
  GET_PRICE_POST_PROVINCE = "getPricePostProvince",
  EDIT_PRICE_POST_PROVINCE = "editPricePostProvince",
  GET_PRICE_POST_DISTRICT = "getPricePostDistrict",
  EDIT_PRICE_POST_DISTRICT = "editPricePostDistrict",
  RESET_PRICE_POST_PROVINCE = "resetPricePostProvince",
  RESET_PRICE_POST_DISTRICT = "resetPricePostDistrict",
  //tourGuide
  GET_TOURGUIDES = "getTourguides",
  GET_TOURGUIDE_INFO = "getTourGuideInfo",
  RESET_TOURGUIDE_INOFO = "resetTourguideInofo",
  GET_TOURGUIDE_PENDING = "getTourGuidePending",
  EDIT_TOURGUIDE = "editTourguide",
  APPROVE_TOURGUIDE_PENDING = "approveTourguidePending",
  REFUSE_TOURGUIDE_PENDING = "refuseTourguidePending",
  UPDATE_DISCOUNT_TOURGUIDE = "updateDiscountTourguide",
  //banner
  GET_BANNERS = "getBanners",
  GET_BANNER_INFO = "getBannerInfo",
  ADD_BANNER = "addBanner",
  ADD_BANNER_ARTICEL = "addBannerArticel",
  EDIT_BANNER = "editBanner",
  EDIT_BANNER_ARTICLE = "editBannerArticle",
  DELETE_BANNER = "deleteBanner",
  //booking buddy
  GET_BOOKINGS = "getBookings",
  GET_BOOKING_INFO = "getBookingInfo",
  GET_BOOKING_BID = "getBookingBid",
  GET_BOOKING_REPORTS = "getBookingReports",
  GET_BOOKING_VIOLATIVE = "getBookingViolative",
  UPDATE_BOOKING_VIOLATIVE = "updateBookingViolative",

  //user manual
  SET_USER_MANUAL_QUERY = "SET_USER_MANUAL_QUERY",

  //notifications
  GET_NOTIFICATIONS = "getNotifications",
  GET_NOTIFICATION_INFO = "getNotificationInfo",
  ADD_NOTIFICATION = "addNotification",
  DELETE_NOTIFICATION = "deleteNotification",

  //report
  GET_REPORT_ACCOUNT = "getReportAccount",
  GET_REPORT_POSTS = "getReportPosts",
  GET_REPORT_POSTSVIP = "getReportPostsvip",
  GET_REPORT_POSTS_INFO = "getReportPostInfo",
  GET_REPORT_POSTSVIP_INFO = "getReportPostsvipInfo",
  //feedback
  GET_FEEDBACKS = "getFeedbacks",
  GET_FEEDBACK_INFO = "getFeedbackInfo",
  DELETE_FEEDBACK = "deleteFeedback",
  HANDLE_FEEDBACKS = "handleFeed",
  ACCEPT_FEEDBACKS = "acceptFeedback",

  //dashboard
  GET_DASHBOARD = "getDashboard",
  GET_DASHBOARD_TURNOVER = "getDashboardTurnover",
  GET_DASHBOARD_TURNOVER_TOTAL = "getDashboardTurnoverTotal",
  GET_DASHBOARD_TURNOVER_CITY = "getDashboardTurnoverCity",

  //sitemap
  GET_SITEMAPS = "getSitemaps",
  GET_SITEMAP_DETAILS = "getSitemapDetails",
  ADD_SITEMAP = "addSitemap",
  DELETE_SITEMAP_INFO = "deleteSitemapInfo",
  GET_SITEMAP_ESTATE = "getSitemapEstate",
  GET_SITEMAP_TRAVEL = "getSitemapTravel",
  GET_SITEMAP_NEWS = "getSitemapNews",
  DELETE_SITEMAP = "deleteSitemap",
  UPDATE_SITEMAP = "updateSitemap",
  UPLOAD_SITEMAP = "uploadSitemap",
  UPDATE_SITEMAP_NAME = "updateSitemapName",

  //article category footer
  GET_ARTICLE_CATEGORY_FOOTER_PARENTS = "getArticleCategoryFooterParents",
  ADD_ARTICLE_CATEGORY_FOOTER = "addArticleCategoryFooter",
  GET_ARTICLE_CATEGORY_FOOTERS = "getArticleCategoryFooters",
  GET_ARTICLE_CATEGORY_FOOTER_INFO = "getArticleCategoryFooterInfo",
  EDIT_ARTICLE_CATEGORY_FOOTER = "editArticleCategoryFooter",
  DELETE_ARTICLE_CATEGORY_FOOTER = "deleteArticleCategoryFooter",
  GET_ARTICLE_CATEGORY_FOOTER_SORT = "getArticleCategoryFooterSort",
  EDIT_ARTICLE_CATEGORY_FOOTER_SORT = "editArticleCategoryFooterSort",
  UPDATE_ARTICLE_CATEGORY_FOOTER_SORT = "updateArticleCategoryFooterSort",

  // building line
  GET_BUILDING_LINE_SELECT = "getBuildingLineSelect"
}
enum Mutations {
  //article
  SET_ARTICLE_QUERY = "SET_ARTICLE_QUERY",
  //commit pack
  SET_COMMITMENT_PACK_QUERY = "SET_COMMITMENT_PACK_QUERY",
  // media
  OPEN_RENAME_FOLDER_FORM = "OPEN_RENAME_FOLDER_FORM",
  CLOSE_RENAME_FOLDER_FORM = "CLOSE_RENAME_FOLDER_FORM",
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  RESET_ERROR = "resetError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  REDIRECT_ROUTER = "redirectRouter",
  //role
  SET_ALL_ROLES = "setAllRoles",
  SET_PERMISSION = "setPermissions",
  RESET_PERMISSIONS = "resetPermissions",
  SET_ROLE_SELECT = "setRoleSelect",
  SET_USERS_ADMIN = "setUsersAdmin",
  SET_USER_ADMIN = "setUserAdmin",
  RESET_USER_ADMIN = "resetUserAdmin",
  SET_SETTING = "setSetting",
  //menu app
  SET_MENU_APP_PARENTS = "setMenuAppParents",
  SET_MENU_APPS = "setMenuApps",
  SET_MENU_APP_INFO = "setMenuAppInfo",
  RESET_MENU_APP_INFO = "resetMenuAppInfo",
  SET_MENU_APP_SORT = "setMenuAppSort",
  RESET_MENU_APP_SORT = "resetMenuAppSort",
  SET_ICON_MENU_APP = "setIconMenuApp",
  SET_CHILD_MENU_APP = "setChildMenuApp",
  //menu
  SET_MENU_PARENTS = "setMenuParents",
  SET_MENUS = "setMenus",
  SET_MENU_INFO = "setMenuInfo",
  RESET_MENU_INFO = "resetMenuInfo",
  SET_MENU_SORT = "setMenuSort",
  RESET_MENU_SORT = "resetMenuSort",
  SET_ICON_MENU = "setIconMenu",
  SET_MENU_SECTION = "setMenuSection",
  //footer
  SET_FOOTER_PARENTS = "setFooterParents",
  SET_FOOTERS = "setFooters",
  SET_FOOTER_INFO = "setFooterInfo",
  RESET_FOOTER_INFO = "resetFooterInfo",
  SET_FOOTER_SORT = "setFooterSort",
  RESET_FOOTER_SORT = "resetFooterSort",
  SET_ICON_FOOTER = "setIconFooter",

  //static page
  SET_PAGES = "setPages",
  SET_PAGE_SELECT = "setPageSelect",
  SET_PAGE_INFO = "setPageInfo",
  SET_IMAGE_PAGE = "setImagePage",
  RESET_PAGE = "resetPage",
  //country
  SET_COUNTRYS = "setCountrys",
  SET_COUNTRY_SELECT = "setCountrySelect",
  SET_COUNTRY_INFO = "setCountryInfo",
  RESET_COUNTRY_INFO = "resetCountryInfo",
  //provinces
  SET_PROVINCES = "setProvinces",
  SET_PROVINCE_SELECT = "setProvinceSelect",
  SET_PROVINCE_INFO = "setProvinceInfo",
  RESET_PROVINCE = "resetProvince",
  SET_POSITION_BRANCH_PROVINCE = "setPositionBranchProvince",
  SET_PROVINCE_INFOTYPE = "setProvinceInfotype",

  //district
  SET_DISTRICTS = "setDistricts",
  SET_DISTRICT_SELECT = "setDistrictSelect",
  SET_DISTRICT_MULTIPLE = "setDistrictMultiple",
  SET_DISTRICT_INFO = "setDistrictInfo",
  RESET_DISTRICT = "resetDistrict",
  DELETE_DISTRICT_SELECT = "deleteDistrictSelect",
  //wards
  SET_WARDS = "setWards",
  SET_WARD_SELECT = "setWardsSelect",
  SET_WARD_INFO = "setWardsInfo",
  RESET_WARDS = "resetWards",
  //seo link
  SET_SEOLINKS = "setSeolinks",
  SET_SEOLINK_INFO = "setSeolinksInfo",
  SET_IMAGE_SEOLINK = "setImageSeolink",
  RESET_SEOLINK = "resetSeolink",
  //position:
  SET_POSITION = "setPosition",
  RESET_POSITION = "resetPosition",
  SET_LATLNG = "setLatlng",
  //tag
  SET_TAG_TYPE = "setTagType",
  SET_TAGS = "setTags",
  SET_TAG_INFO = "setTagInfo",
  RESET_TAG_INFO = "resetTagInfo",
  //UI Form
  SET_UIFORMS = "setUiforms",
  SET_UIFORM_INFO = "setUiformInfo",
  SET_UIFORM_SELECT = "setUiformSelect",
  SET_UIFORM_CREATE = "setUiformCreate",
  RESET_UIFORM = "resetUiform",
  //UI Section
  SET_UISECTIONS = "setUisections",
  SET_UISECTION_INFO = "setUisectionInfo",
  SET_UISECTION_PAGE = "setUisectionPage",
  SET_UISECTION_OF_PAGE = "setUisectionOfPage",
  RESET_UISECTION_INFO = "resetUisectionInfo",
  RESET_UISECTION_IMAGE = "resetUisectionImage",
  SET_UISECTION_IMAGE = "setUisectionImage",
  SET_UISECTION_SORT = "setUisectionSort",
  //Watermark
  SET_WATERMARKS = "setWatermarks",
  SET_WATERMARK_INFO = "setWatermarkInfo",
  SET_WATERMARK_SELECT = "setWatermarkSelect",
  RESET_WATERMARK = "resetWatermark",
  RESET_WATERMARK_INFO = "resetWatermarkInfo",
  //Estate-category Types
  SET_TYPES = "setTypes",
  SET_TYPE_INFO = "setTypeInfo",
  SET_TYPE_SORT = "setTypeSort",
  SET_TYPE_SELECT = "setTypeSelect",
  SET_TYPE_ICON = "setTypeIcon",
  RESET_TYPE_INFO = "resetTypeInfo",
  RESET_TYPE_ICON = "resetTypeIcon",
  //forms
  SET_FORMS = "setForms",
  SET_FORM_INFO = "setFormInfo",
  SET_FORM_SORT = "setFormSort",
  SET_FORM_SELECT = "setFormSelect",
  RESET_FORM_INFO = "resetFormInfo",
  RESET_FORM_SORT = "resetFormSort",
  //estate
  SET_DIRECTION = "setDirection",
  SET_ESTATES = "setEstates",
  SET_ESTATE_INFO = "setEstatesInfo",
  SET_DIRECTION_SELECT = "setDirectionsSelect",
  RESET_ESTATE_INFO = "resetEstatesInfo",
  SET_ESTATE_DETAILS = "setEstateDetails",
  SET_ESTATE_TYPES = "setEstateTypes",
  SET_ESTATE_SEO = "setEstateSeo",
  SET_ESTATE_TAGS = "getEstateTags",
  SET_ESTATE_TAGS_SEARCH = "setEstateTagsSearch",
  SET_ESTATE_COMMENTS = "setEstateComments",
  SET_ESTATE_COMMENT_INFO = "setEstateCommentInfo",
  SET_ESTATE_COMMENT_CHILD = "setEstateCommentChild",
  SET_ESTATE_REPORT = "setEstateReport",
  SET_ESTATE_COMMENT_ALL = "setEstateCommentAll",
  //Travel
  SET_TRAVELS = "setTravels",
  SET_TRAVEL_INFO = "setTravelInfo",
  SET_TRAVEL_DETAILS = "setTravelDetails",
  SET_TRAVEL_TYPES = "setTravelTypes",
  SET_TRAVEL_SEO = "setTravelSeo",
  SET_TRAVEL_TAGS = "setTravelTags",
  SET_TRAVEL_TAGS_SEARCH = "setTravelTagsSearch",
  SET_TRAVEL_REPORT = "setTravelReport",
  //comments
  SET_COMMENTS = "setComments",
  SET_COMMENT_CHILD = "setCommentChild",
  SET_COMMENT_TYPE = "setCommentType",
  SET_COMMENT_INFO = "setCommentInfo",
  //url banned
  SET_URL_BANNED = "setUrlBanned",
  //keyword banned
  SET_KEYWORDS_BANNED = "setKeywordsBanned",
  //user account
  SET_USER_ACCOUNT = "setUserAccount",
  SET_USER_ACCOUNT_INFO = "setUserAccountInfo",
  SET_USER_ACCOUNT_LOGS = "setUserAccountLogs",
  SET_USER_TRANSACTIONS = "setUserTransactions",
  //priceVip
  SET_PRICE_VIPS = "setPriceVips",
  SET_PRICE_VIP_INFO = "setPriceVipInfo",
  SET_PRICE_VIP_TYPE = "setPriceVipType",
  SET_PRICE_VIP_PROVINCE = "setPriceVipProvince",
  SET_PRICE_VIP_DISTRICT = "setPriceVipDistrict",
  //pricePost
  SET_PRICE_POSTS = "setPricePosts",
  SET_PRICE_POST_INFO = "setPricePostInfo",
  SET_PRICE_POST_DURATION = "setPricePostDuration",
  SET_PRICE_POST_PROVINCE = "setPricePostProvince",
  SET_PRICE_POST_DISTRICT = "setPricePostDistrict",
  //tourGuide
  SET_TOURGUIDES = "setTourguides",
  SET_TOURGUIDE_INFO = "setTourguideInfo",
  SET_TOURGUIDE_PENDING = "setTourguidePending",
  //banner
  SET_BANNERS = "setBanners",
  SET_BANNER_INFO = "setBannerInfo",
  //booking buddy
  SET_BOOKINGS = "setBookings",
  SET_BOOKING_INFO = "setBookingInfo",
  SET_BOOKING_BID = "setBookingBid",
  SET_BOOKING_REPORTS = "setBookingReports",
  SET_BOOKING_VIOLATIVE = "setBookingViolative",

  //user manual
  SET_USER_MANUAL_QUERY = "SET_USER_MANUAL_QUERY",
  //notifications
  SET_NOTIFICATIONS = "setNotifications",
  SET_NOTIFICATION_INFO = "setNotificationInfo",
  RESET_NOTIFICATION = "resetNotification",
  //report
  SET_REPORT_ACCOUNT = "setReportAccount",
  SET_REPORT_POSTS = "setReportPosts",
  SET_REPORT_POSTSVIP = "seytReportPostsvip",
  SET_REPORT_POSTS_INFO = "setReportPostsInfo",
  SET_REPORT_POSTSVIP_INFO = "setReportPostsvipInfo",
  //feedback
  SET_FEEDBACKS = "setFeedbacks",
  SET_FEEDBACK_INFO = "setFeedbackInfo",
  //dashboard
  SET_DASHBOARD = "setDashboard",
  SET_DASHBOARD_TURNOVER = "setDashboardTurnover",
  SET_DASHBOARD_TURNOVER_TOTAL = "setDashboardTurnoverTotal",
  SET_DASHBOARD_TURNOVER_CITY = "setDashboardTurnoverCity",
  //sitemap
  SET_SITEMAPS = "setSitemaps",
  SET_SITEMAP_DETAILS = "setSitemapDetails",
  SET_SITEMAP_ESTATE = "setSitemapEstate",
  SET_SITEMAP_TRAVEL = "setSitemapTravel",
  SET_SITEMAP_NEWS = "setSitemapNews",
  SET_SITEMAP_NAME = "setSitemapName",

  //article category footer
  SET_ARTICLE_CATEGORY_FOOTER_PARENTS = "setArticleCategoryFooterParents",
  SET_ARTICLE_CATEGORY_FOOTERS = "setArticleCategoryFooters",
  SET_ARTICLE_CATEGORY_FOOTER_INFO = "setArticleCategoryFooterInfo",
  RESET_ARTICLE_CATEGORY_FOOTER_INFO = "resetArticleCategoryFooterInfo",
  SET_ARTICLE_CATEGORY_FOOTER_SORT = "setArticleCategoryFooterSort",
  RESET_ARTICLE_CATEGORY_FOOTER_SORT = "resetArticleCategoryFooterSort",

  // building line
  SET_BUILDING_LINE_SELECT = "setBuildingLineSelect"
}
enum EndPointAPI {
  GET_USER_INFO = "/admin/UserAdmin"
}

export { Actions, Mutations, EndPointAPI }
