import ApiService from "@/core/services/ApiService"
import { ActionTree } from "vuex"
import { Actions, Mutations } from "@/store/enums/StoreEnums"
import { State } from "./state"

export default <ActionTree<State, any>>{
  [Actions.GET_PRICE_POSTS](store, payload) {
    return ApiService.query(`/admin/pricePosts`, { params: payload })
      .then(({ data }) => {
        store.commit(Mutations.SET_PRICE_POSTS, data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.GET_PRICE_POST_INFO](store, payload) {
    return ApiService.get(`/admin/pricepost/${payload}`)
      .then(({ data }) => {
        store.commit(Mutations.SET_PRICE_POST_INFO, data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.ADD_PRICE_POST](store, payload) {
    return ApiService.post(`/admin/pricePost`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.EDIT_PRICE_POST](store, payload) {
    return ApiService.put(`/admin/pricePost/${payload.id}`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.GET_PRICE_POST_DURATION](store) {
    return ApiService.get(`/admin/pricePost/duration`)
      .then(({ data }) => {
        store.commit(Mutations.SET_PRICE_POST_DURATION, data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.GET_PRICE_POST_PROVINCE](store, payload) {
    return ApiService.get(`/admin/pricepost/province/${payload}`)
      .then(({ data }) => {
        store.commit(Mutations.SET_PRICE_POST_PROVINCE, data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.EDIT_PRICE_POST_PROVINCE](store, payload) {
    return ApiService.patch(
      `/admin/pricepost/province/${payload.id}`,
      payload.data
    )
      .then(() => {
        store.commit(Mutations.RESET_ERROR)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.GET_PRICE_POST_DISTRICT](store, payload) {
    return ApiService.get(`/admin/pricepost/district/${payload}`)
      .then(({ data }) => {
        store.commit(Mutations.SET_PRICE_POST_DISTRICT, data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.EDIT_PRICE_POST_DISTRICT](store, payload) {
    return ApiService.patch(`/admin/pricepost/district/${payload.id}`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.RESET_PRICE_POST_PROVINCE](store) {
    store.commit(Mutations.SET_PRICE_POST_PROVINCE, {})
  },
  [Actions.RESET_PRICE_POST_DISTRICT](store) {
    store.commit(Mutations.SET_PRICE_POST_DISTRICT, {})
  }
}
