import LayoutConfigTypes from "@/core/config/LayoutConfigTypes"
import layoutConfig from "@/core/config/DefaultLayoutConfig"

interface ConfigInfo {
  config: LayoutConfigTypes
}

export class State implements ConfigInfo {
  config = layoutConfig
}
