import ApiService from "@/core/services/ApiService"
import { ActionTree } from "vuex"
import { Actions, Mutations } from "@/store/enums/StoreEnums"
import { State } from "./state"
export default <ActionTree<State, any>>{
  [Actions.GET_TYPES](store, payload) {
    return ApiService.query(`/admin/types`, {
      params: { branchType: payload }
    })
      .then(({ data }) => {
        store.commit(Mutations.SET_TYPES, data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.GET_TYPE_INFO](store, payload) {
    return ApiService.get(`/admin/type/${payload}`)
      .then(({ data }) => {
        console.log(data)
        store.commit(Mutations.SET_TYPE_INFO, data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.GET_TYPE_SORT](store, payload) {
    return ApiService.get(`/admin/type/sort/${payload.id}/${payload.type}`)
      .then(({ data }) => {
        console.log(data)
        store.commit(Mutations.SET_TYPE_SORT, data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.GET_TYPE_SELECT](store, payload) {
    return ApiService.query(`/admin/type/parents`, {
      params: { branchType: payload }
    })
      .then(({ data }) => {
        store.commit(Mutations.SET_TYPE_SELECT, data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },

  [Actions.GET_TYPE_ICON](store, payload) {
    return ApiService.get(`/admin/type/icon/${payload}`)
      .then(({ data }) => {
        store.commit(Mutations.SET_TYPE_ICON, data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.EDIT_TYPE](store, payload) {
    return ApiService.put(`/admin/type/${payload.id}`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR)
        store.commit(Mutations.RESET_TYPE_INFO)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.EDIT_TYPE_SORT](store, payload) {
    return ApiService.patch(`/admin/type/sort`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.EDIT_TYPE_ICON](store, payload) {
    return ApiService.patch(`/admin/type/icon/${payload.id}`, payload.data)
      .then(() => {
        store.commit(Mutations.RESET_ERROR)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.ADD_TYPE](store, payload) {
    return ApiService.post(`/admin/type`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.DELETE_TYPE](store, payload) {
    return ApiService.delete(`/admin/type/${payload}`)
      .then(() => {
        store.commit(Mutations.RESET_ERROR)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.UPDATE_TYPE_SORT](store, payload) {
    store.commit(Mutations.SET_TYPE_SORT, payload)
  }
}
