import { MutationTree } from "vuex"
import { Mutations } from "@/store/enums/StoreEnums"
import { State } from "./state"
import { UISectionInfo, UISectionSelect } from "@/interfaces/IUISection"

export default <MutationTree<State>>{
  [Mutations.SET_UISECTIONS](state, payload) {
    state.uiSections = payload
  },
  [Mutations.SET_UISECTION_INFO](state, payload) {
    state.uiSectionInfo = payload
  },
  [Mutations.SET_UISECTION_PAGE](state, payload) {
    state.uiSetionPage = payload
  },
  [Mutations.SET_UISECTION_OF_PAGE](state, payload) {
    state.uiSectionOfPage = payload
  },
  [Mutations.SET_UISECTION_IMAGE](state, payload) {
    state.uiSectionImage = payload
  },
  [Mutations.RESET_UISECTION_INFO](state) {
    state.uiSectionInfo = {} as UISectionInfo
    state.uiSetionPage = {} as UISectionSelect[]
    state.uiSectionOfPage = {} as UISectionSelect[]
  },
  [Mutations.RESET_UISECTION_IMAGE](state) {
    state.uiSectionImage = {}
  },
  [Mutations.SET_UISECTION_SORT](state, payload) {
    state.uiSectionSort = payload
  }
}
