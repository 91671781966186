import { createStore } from "vuex"
import { config } from "vuex-module-decorators"

import AuthModule from "@/store/modules/AuthModule"
import BodyModule from "@/store/modules/BodyModule"
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule"
import ConfigModule from "@/store/modules/ConfigModule"
import DashboardModule from "@/store/modules/DashboardModule"

config.rawError = true

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    DashboardModule
  }
})

export default store
