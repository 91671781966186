export enum UserManualOrder {
  DESC = 1,
  ASC = 2
}

export interface IUserManualQuery {
  page: number
  pageSize: number
  order?: UserManualOrder
  published?: boolean
  name: string
}

export interface IUserManual {
  name: string
  id: number
  icon: string
  published: boolean
  isBlockComment: boolean
}

export interface UserManualTranslation {
  language: string
  isDefaultLanguage: boolean
  name: string
  slug: string
  h1: string
  short: string
  body: string
  seoTitle: string
  seoDescription: string
  seoKeywords: string
}

export interface IUserManualCreateModel {
  iconFile: File
  published: boolean
  isBlockComment: boolean
  userManualTranslations: UserManualTranslation[]
}

export interface IUserManualDetailModel {
  id: number
  icon: string
  iconFile: File
  published: boolean
  isBlockComment: boolean
  userManualTranslations: UserManualTranslation[]
}
