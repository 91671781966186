import ApiService from "@/core/services/ApiService"
import { ActionTree } from "vuex"
import { Actions, Mutations } from "@/store/enums/StoreEnums"
import { State } from "./state"

export default <ActionTree<State, any>>{
  [Actions.GET_REPORT_ACCOUNT](store, payload) {
    return ApiService.query(`/admin/report/account`, { params: payload })
      .then(({ data }) => {
        store.commit(Mutations.SET_REPORT_ACCOUNT, data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.GET_REPORT_POSTS](store, payload) {
    return ApiService.query(`/admin/report/posts`, { params: payload })
      .then(({ data }) => {
        store.commit(Mutations.SET_REPORT_POSTS, data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.GET_REPORT_POSTSVIP](store, payload) {
    return ApiService.query(`/admin/report/postsVip`, { params: payload })
      .then(({ data }) => {
        store.commit(Mutations.SET_REPORT_POSTSVIP, data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
}
