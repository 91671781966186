import ApiService from "@/core/services/ApiService"
import { ActionTree } from "vuex"
import { Actions, Mutations } from "@/store/enums/StoreEnums"
import { State } from "./state"
export default <ActionTree<State, any>>{
  [Actions.GET_FORMS](store) {
    return ApiService.get(`/admin/forms`)
      .then(({ data }) => {
        store.commit(Mutations.SET_FORMS, data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.GET_FORM_INFO](store, payload) {
    return ApiService.get(`/admin/form/${payload}`)
      .then(({ data }) => {
        store.commit(Mutations.SET_FORM_INFO, data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.GET_FORM_SORT](store, payload) {
    return ApiService.get(`/admin/form/sort/${payload}`)
      .then(({ data }) => {
        store.commit(Mutations.SET_FORM_SORT, data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.GET_FORM_SELECT](store) {
    return ApiService.get(`/admin/form/parents`)
      .then(({ data }) => {
        store.commit(Mutations.SET_FORM_SELECT, data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.EDIT_FORM](store, payload) {
    return ApiService.put(`/admin/form/${payload.id}`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR)
        store.commit(Mutations.RESET_FORM_INFO)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.EDIT_FORM_SORT](store, payload) {
    return ApiService.patch(`/admin/form/sort`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR)
        store.commit(Mutations.RESET_FORM_SORT)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.ADD_FORM](store, payload) {
    return ApiService.post(`/admin/form`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.DELETE_FORM](store, payload) {
    return ApiService.delete(`/admin/form/${payload}`)
      .then(() => {
        store.commit(Mutations.RESET_ERROR)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.UPDATE_FORM_SORT](store, payload) {
    store.commit(Mutations.SET_FORM_SORT, payload)
  }
}
