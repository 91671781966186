import ApiService from "@/core/services/ApiService"
import { Actions, Mutations } from "@/store/enums/StoreEnums"
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators"
import { ResultObj } from "../../interfaces/IApiResult"
import { ISeoLink, SeoLinkDetail } from "@/interfaces/ISeoLink"

@Module
export default class SeoLinkModule extends VuexModule {
  seolinks = {} as ResultObj<ISeoLink>
  seolinkInfo = {} as SeoLinkDetail
  imageSeoLink = {}
  /**
   * Get seolinks
   * @returns ResultObj<ISeoLink>
   */
  get currentSeolinks(): ResultObj<ISeoLink> {
    return this.seolinks
  }

  /**
   * Get seolink Info
   * @returns SeoLinkDetail
   */
  get currentSeoLinkInfo(): SeoLinkDetail {
    return this.seolinkInfo
  }
  /**
   * Get image SeoLink
   * @returns any
   */
  get currentImageSeoLink() {
    return this.imageSeoLink
  }

  @Mutation
  [Mutations.SET_SEOLINKS](payload): void {
    this.seolinks = payload
  }
  @Mutation
  [Mutations.SET_SEOLINK_INFO](payload): void {
    this.seolinkInfo = payload
  }
  @Mutation
  [Mutations.SET_IMAGE_SEOLINK](payload): void {
    this.imageSeoLink = payload
  }

  @Mutation
  [Mutations.RESET_SEOLINK](): void {
    this.seolinks = {} as ResultObj<ISeoLink>
    this.seolinkInfo = {} as SeoLinkDetail
    this.imageSeoLink = {}
  }

  @Action
  [Actions.GET_SEOLINKS](payload) {
    return ApiService.query(`/admin/seolinks`, { params: payload })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SEOLINKS, data.resultObj)
      })
      .catch(({ response }) => {
        console.log(response)
      })
  }
  @Action
  [Actions.GET_SEOLINK_INFO](payload) {
    return ApiService.get(`/admin/seolink/${payload}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SEOLINK_INFO, data.resultObj)
      })
      .catch(({ response }) => {
        console.log(response)
      })
  }
  @Action
  [Actions.ADD_SEOLINK](payload) {
    return ApiService.post(`/admin/seolink`, payload)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
  @Action
  [Actions.EDIT_SEOLINK](payload) {
    return ApiService.put(`/admin/seolink/${payload.id}`, payload)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }

  @Action
  [Actions.DELETE_SEOLINK](payload) {
    return ApiService.delete(`/admin/seolink/${payload}`)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }

  @Action
  [Actions.GET_IMAGE_SEOLINK](payload) {
    return ApiService.get(`/admin/seolink/image/${payload}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_IMAGE_SEOLINK, data.resultObj)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
  @Action
  [Actions.EDIT_IMAGE_SEOLINK](payload) {
    return ApiService.patch(`/admin/seolink/image/${payload.id}`, payload.data)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
}
