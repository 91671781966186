import { MutationTree } from "vuex"
import { Mutations } from "@/store/enums/StoreEnums"
import { State } from "./state"

export default <MutationTree<State>>{
  [Mutations.SET_REPORT_POSTS](state, payload) {
    state.reportPosts = payload
  },
  [Mutations.SET_REPORT_POSTSVIP](state, payload) {
    state.reportPostVip = payload
  },
  [Mutations.SET_REPORT_ACCOUNT](state, payload) {
    state.reportAccount = payload
  },
  [Mutations.SET_REPORT_POSTS_INFO](state, payload) {
    state.reportPostsInfo = payload
  },
  [Mutations.SET_REPORT_POSTSVIP_INFO](state, payload) {
    state.reportPostsVipInfo = payload
  }
}
