import { MutationTree } from "vuex"
import { Mutations } from "@/store/enums/StoreEnums"
import { State } from "./state"

export default <MutationTree<State>>{
  [Mutations.SET_DIRECTION](state, payload) {
    state.directions = payload
  },
  [Mutations.SET_DIRECTION_SELECT](state, payload) {
    state.directionSelect = payload
  },
  [Mutations.SET_ESTATES](state, payload) {
    state.estates = payload
  },
  [Mutations.SET_ESTATE_INFO](state, payload) {
    state.estateInfo = payload
  },
  [Mutations.SET_ESTATE_DETAILS](state, payload) {
    state.estateDetails = payload
  },
  [Mutations.SET_ESTATE_TYPES](state, payload) {
    state.estateTypes = payload
  },
  [Mutations.SET_ESTATE_SEO](state, payload) {
    state.estateSEO = payload
  },
  [Mutations.SET_ESTATE_TAGS](state, payload) {
    state.estateTags = payload
  },
  [Mutations.SET_ESTATE_TAGS_SEARCH](state, payload) {
    state.estateTagsSearch = payload
  },
  [Mutations.SET_ESTATE_COMMENTS](state, payload) {
    state.estateComments = payload
  },
  [Mutations.SET_ESTATE_COMMENT_INFO](state, payload) {
    state.estateCommentInfo = payload
  },
  [Mutations.SET_ESTATE_COMMENT_CHILD](state, payload) {
    state.estateCommentChild = payload
  },
  [Mutations.SET_ESTATE_REPORT](state, payload) {
    state.estateReports = payload
  },
  [Mutations.SET_ESTATE_COMMENT_ALL](state, payload) {
    state.estateCommentAlls = payload
  }
}
