import ApiService from "@/core/services/ApiService"
import { ActionTree } from "vuex"
import { Actions, Mutations } from "@/store/enums/StoreEnums"
import { State } from "./state"
export default <ActionTree<State, any>>{
  [Actions.GET_URL_BANNED](store) {
    return ApiService.get(`/admin/urlbanned`)
      .then(({ data }) => {
        store.commit(Mutations.SET_URL_BANNED, data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },

  [Actions.ADD_KEYWORD_BANNED](store, payload) {
    return ApiService.post(`/admin/urlbanned/create`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.DELETE_KEYWORD_BANNED](store, payload) {
    return ApiService.post(`/admin/urlbanned/delete`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.UPDATE_URL_BANNED](store, payload) {
    return ApiService.put(`/admin/urlbanned`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
}
