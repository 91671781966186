import JwtService from "@/core/services/JwtService"
import { ValidationError } from "../../../interfaces/IApiResult"
import { User } from "../../../interfaces/Permission"

interface UserAuthInfo {
  errors: unknown
  user: User
  isAuthenticated: boolean
}

export class State implements UserAuthInfo {
  errors = {} as ValidationError[]
  user = {} as User
  isAuthenticated = !!JwtService.getToken()
}
