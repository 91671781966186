import { MutationTree } from "vuex"
import { Mutations } from "@/store/enums/StoreEnums"
import { State } from "./state"

export default <MutationTree<State>>{
  [Mutations.SET_PRICE_VIPS](state, payload) {
    state.priceVips = payload
  },
  [Mutations.SET_PRICE_VIP_INFO](state, payload) {
    state.priceVipInfo = payload
  },
  [Mutations.SET_PRICE_VIP_TYPE](state, payload) {
    state.priceVipTypes = payload
  },
  [Mutations.SET_PRICE_VIP_PROVINCE](state, payload) {
    state.priceVipProvinces = payload
  },
  [Mutations.SET_PRICE_VIP_DISTRICT](state, payload) {
    state.priceVipDistricts = payload
  }
}
