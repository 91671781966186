import ApiService from "@/core/services/ApiService"
import { Actions, Mutations } from "@/store/enums/StoreEnums"
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators"
import { BuildingLineSelect } from "@/interfaces/IBuildingLine"

@Module
export default class BuildingLineModule extends VuexModule {
  buildingLineSelect = {} as BuildingLineSelect[]
  /**
   * Get districts Select
   * @returns BuildingLineSelect[]
   */
  get getBuildingLineSeleted(): BuildingLineSelect[] {
    return this.buildingLineSelect
  }

  @Mutation
  [Mutations.SET_BUILDING_LINE_SELECT](payload): void {
    this.buildingLineSelect = payload
  }

  @Action
  [Actions.GET_BUILDING_LINE_SELECT]() {
    return ApiService.get(`/Type/buildingLine`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_BUILDING_LINE_SELECT, data.resultObj)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
}
