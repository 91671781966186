import ApiService from "@/core/services/ApiService"
import { ActionTree } from "vuex"
import { Actions, Mutations } from "@/store/enums/StoreEnums"
import { State } from "./state"

export default <ActionTree<State, any>>{
  [Actions.GET_BANNERS](store, payload) {
    return ApiService.query(`/admin/banners`, { params: payload })
      .then(({ data }) => {
        store.commit(Mutations.SET_BANNERS, data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.GET_BANNER_INFO](store, payload) {
    return ApiService.get(`/admin/banner/${payload}`)
      .then(({ data }) => {
        store.commit(Mutations.SET_BANNER_INFO, data.resultObj)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.ADD_BANNER_ARTICEL](store, payload) {
    return ApiService.post(`/admin/banner/article`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.ADD_BANNER](store, payload) {
    return ApiService.post(`/admin/banner`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.EDIT_BANNER](store, payload) {
    return ApiService.put(`/admin/banner/${payload.id}`, payload.data)
      .then(() => {
        store.commit(Mutations.RESET_ERROR)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.EDIT_BANNER_ARTICLE](store, payload) {
    return ApiService.put(`/admin/banner/article/${payload.id}`, payload.data)
      .then(() => {
        store.commit(Mutations.RESET_ERROR)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  },
  [Actions.DELETE_BANNER](store, payload) {
    return ApiService.delete(`/admin/banner/${payload}`)
      .then(() => {
        store.commit(Mutations.RESET_ERROR)
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
}
