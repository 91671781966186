import { MutationTree } from "vuex"
import { Mutations } from "@/store/enums/StoreEnums"
import layoutConfig from "@/core/config/DefaultLayoutConfig"
import merge from "deepmerge"
import { State } from "./state"

export default <MutationTree<State>>{
  [Mutations.SET_LAYOUT_CONFIG](state, payload) {
    state.config = payload
  },

  [Mutations.RESET_LAYOUT_CONFIG](state) {
    state.config = { ...layoutConfig }
  },

  [Mutations.OVERRIDE_PAGE_LAYOUT_CONFIG](state, payload) {
    state.config = merge(state.config, payload)
  }
}
