import { GetterTree } from "vuex"
import { State } from "./state"

export default <GetterTree<State, any>>{
  currentNotifications(state: State) {
    return state.notifications
  },
  currentNotificationInfo(state: State) {
    return state.notificationInfo
  }
}
