import { MutationTree } from "vuex"
import { Mutations } from "@/store/enums/StoreEnums"
import { State } from "./state"

export default <MutationTree<State>>{
  [Mutations.SET_DASHBOARD](state, payload) {
    state.dashboard = payload
  },
  [Mutations.SET_DASHBOARD_TURNOVER](state, payload) {
    state.dashboardTurnover = payload
  },
  [Mutations.SET_DASHBOARD_TURNOVER_TOTAL](state, payload) {
    state.dashboardTurnoverTotal = payload
  },
  [Mutations.SET_DASHBOARD_TURNOVER_CITY](state, payload) {
    state.dashboardTurnoverCity = payload
  }
}
