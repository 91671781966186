import ApiService from "@/core/services/ApiService"
import { Actions, Mutations } from "@/store/enums/StoreEnums"
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators"
import { ValidationError } from "../../interfaces/IApiResult"
import {
  ArticleCategoryMenuParentModel,
  ArticleCategoryMenuModel
} from "../../interfaces/IArticleCategoryFooter"

@Module
export default class ArticleCategoryFooterModule extends VuexModule {
  errors = {} as ValidationError[]
  articleCategoryFooterParents = {} as ArticleCategoryMenuParentModel[]
  articleCategoryFooters = {} as ArticleCategoryMenuModel[]
  articleCategoryFooterInfo = {}
  articleCategoryFooterSort = {}
  /**
   * Get getFooterParent
   * @returns MenuParentModel[]
   */
  get getArticleCategoryFooterParent(): ArticleCategoryMenuParentModel[] {
    return this.articleCategoryFooterParents
  }
  /**
   * Get getFooter
   * @returns MenuModel[]
   */
  get currentArticleCategoryFooter(): ArticleCategoryMenuModel[] {
    return this.articleCategoryFooters
  }
  /**
   * Get FooterInfo
   * @returns footerInfo
   */
  get currentArticleCategoryFooterInfo() {
    return this.articleCategoryFooterInfo
  }
  /**
   * Get footerInfo
   * @returns footerSort
   */
  get currentArticleCategoryFooterSort() {
    return this.articleCategoryFooterSort
  }
  @Mutation
  [Mutations.SET_ARTICLE_CATEGORY_FOOTER_PARENTS](payload): void {
    this.articleCategoryFooterParents = payload
  }
  @Mutation
  [Mutations.SET_ARTICLE_CATEGORY_FOOTERS](payload): void {
    this.articleCategoryFooters = payload
  }
  @Mutation
  [Mutations.SET_ARTICLE_CATEGORY_FOOTER_INFO](payload): void {
    this.articleCategoryFooterInfo = payload
  }
  @Mutation
  [Mutations.RESET_ARTICLE_CATEGORY_FOOTER_INFO](): void {
    this.articleCategoryFooterInfo = {}
  }
  @Mutation
  [Mutations.SET_ARTICLE_CATEGORY_FOOTER_SORT](payload): void {
    this.articleCategoryFooterSort = payload
  }
  @Mutation
  [Mutations.RESET_ARTICLE_CATEGORY_FOOTER_SORT](): void {
    this.articleCategoryFooterSort = {}
  }
  @Action
  [Actions.GET_ARTICLE_CATEGORY_FOOTER_PARENTS](categoryId) {
    return ApiService.get(`/admin/ArticleCategoryFooter/parents`, categoryId)
      .then(({ data }) => {
        this.context.commit(
          Mutations.SET_ARTICLE_CATEGORY_FOOTER_PARENTS,
          data.resultObj
        )
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
  @Action
  [Actions.ADD_ARTICLE_CATEGORY_FOOTER](footer) {
    return ApiService.post(`/admin/ArticleCategoryFooter`, footer)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
  @Action
  [Actions.GET_ARTICLE_CATEGORY_FOOTERS](categoryId) {
    return ApiService.get(`/admin/ArticleCategoryFooter/footers`, categoryId)
      .then(({ data }) => {
        this.context.commit(
          Mutations.SET_ARTICLE_CATEGORY_FOOTERS,
          data.resultObj
        )
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
  @Action
  [Actions.GET_ARTICLE_CATEGORY_FOOTER_INFO](payload) {
    return ApiService.get(`/admin/ArticleCategoryFooter/${payload}`)
      .then(({ data }) => {
        this.context.commit(
          Mutations.SET_ARTICLE_CATEGORY_FOOTER_INFO,
          data.resultObj
        )
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
  @Action
  [Actions.EDIT_ARTICLE_CATEGORY_FOOTER](payload) {
    return ApiService.put(`/admin/ArticleCategoryFooter/${payload.id}`, payload)
      .then(({ data }) => {
        this.context.commit(Mutations.RESET_ARTICLE_CATEGORY_FOOTER_INFO)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
  @Action
  [Actions.DELETE_ARTICLE_CATEGORY_FOOTER](payload) {
    return ApiService.delete(`/admin/ArticleCategoryFooter/${payload}`)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
  @Action
  [Actions.GET_ARTICLE_CATEGORY_FOOTER_SORT](payload) {
    return ApiService.get(`/admin/ArticleCategoryFooter/sort/${payload}`)
      .then(({ data }) => {
        this.context.commit(
          Mutations.SET_ARTICLE_CATEGORY_FOOTER_SORT,
          data.resultObj
        )
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
  @Action
  [Actions.EDIT_ARTICLE_CATEGORY_FOOTER_SORT](payload) {
    return ApiService.patch(`/admin/ArticleCategoryFooter/sort`, payload)
      .then(() => {
        this.context.commit(Mutations.RESET_ARTICLE_CATEGORY_FOOTER_SORT)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
  @Action
  [Actions.UPDATE_ARTICLE_CATEGORY_FOOTER_SORT](payload) {
    this.context.commit(Mutations.SET_ARTICLE_CATEGORY_FOOTER_SORT, payload)
  }
}
