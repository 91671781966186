import { GetterTree } from "vuex"
import { State } from "./state"

export default <GetterTree<State, any>>{
  currentBookings(state: State) {
    return state.bookings
  },
  currentBookingInfo(state: State) {
    return state.bookingInfo
  },
  currentBookingBid(state: State) {
    return state.bookingBid
  },
  currentBookingReports(state: State) {
    return state.bookingReports
  },
  currentBookingViolative(state: State) {
    return state.bookingViolative
  }
}
