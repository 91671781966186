import { GetterTree } from "vuex"
import { State } from "./state"

export default <GetterTree<State, any>>{
  currentFeedbacks(state: State) {
    return state.feedbacks
  },
  currentFeedbackInfo(state: State) {
    return state.feedbackInfo
  }
}
