import ApiService from "@/core/services/ApiService"
import { Actions, Mutations } from "@/store/enums/StoreEnums"
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators"
import { ValidationError } from "../../interfaces/IApiResult"
import { MenuParentModel, MenuModel } from "../../interfaces/Config"

@Module
export default class FooterModule extends VuexModule {
  errors = {} as ValidationError[]
  footerParents = {} as MenuParentModel[]
  footers = {} as MenuModel[]
  footerInfo = {}
  footerSort = {}
  footerIcon = {}
  /**
   * Get getFooterParent
   * @returns MenuParentModel[]
   */
  get getFooterParent(): MenuParentModel[] {
    return this.footerParents
  }
  /**
   * Get getFooter
   * @returns MenuModel[]
   */
  get currentFooter(): MenuModel[] {
    return this.footers
  }
  /**
   * Get FooterInfo
   * @returns footerInfo
   */
  get currentFooterInfo() {
    return this.footerInfo
  }
  /**
   * Get footerInfo
   * @returns footerSort
   */
  get currentFooterSort() {
    return this.footerSort
  }
  /**
   * Get footerIcon
   * @returns footerIcon
   */
  get currentFooterIcon() {
    return this.footerIcon
  }
  @Mutation
  [Mutations.SET_FOOTER_PARENTS](payload): void {
    this.footerParents = payload
  }
  @Mutation
  [Mutations.SET_FOOTERS](payload): void {
    this.footers = payload
  }
  @Mutation
  [Mutations.SET_FOOTER_INFO](payload): void {
    this.footerInfo = payload
  }
  @Mutation
  [Mutations.RESET_FOOTER_INFO](): void {
    this.footerInfo = {}
  }
  @Mutation
  [Mutations.SET_FOOTER_SORT](payload): void {
    this.footerSort = payload
  }
  @Mutation
  [Mutations.RESET_FOOTER_SORT](): void {
    this.footerSort = {}
  }
  @Mutation
  [Mutations.SET_ICON_FOOTER](payload): void {
    this.footerIcon = payload
  }
  @Action
  [Actions.GET_FOOTER_PARENTS]() {
    return ApiService.get(`/admin/footer/parents`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_FOOTER_PARENTS, data.resultObj)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
  @Action
  [Actions.ADD_FOOTER](footer) {
    return ApiService.post(`/admin/footer`, footer)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
  @Action
  [Actions.GET_FOOTERS]() {
    return ApiService.get(`/admin/footers`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_FOOTERS, data.resultObj)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
  @Action
  [Actions.GET_FOOTER_INFO](payload) {
    return ApiService.get(`/admin/footer/${payload}`)
      .then(({ data }) => {
        console.log(data.resultObj)
        this.context.commit(Mutations.SET_FOOTER_INFO, data.resultObj)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
  @Action
  [Actions.EDIT_FOOTER](payload) {
    return ApiService.put(`/admin/footer/${payload.id}`, payload)
      .then(({ data }) => {
        console.log(data.resultObj)
        this.context.commit(Mutations.RESET_FOOTER_INFO)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
  @Action
  [Actions.DELETE_FOOTER](payload) {
    return ApiService.delete(`/admin/footer/${payload}`)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
  @Action
  [Actions.GET_FOOTER_SORT](payload) {
    return ApiService.get(`/admin/footer/sort/${payload}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_FOOTER_SORT, data.resultObj)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
  @Action
  [Actions.EDIT_FOOTER_SORT](payload) {
    return ApiService.patch(`/admin/footer/sort`, payload)
      .then(() => {
        this.context.commit(Mutations.RESET_FOOTER_SORT)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
  @Action
  [Actions.GET_ICON_FOOTER](payload) {
    return ApiService.get(`/admin/footer/icon/${payload}`)
      .then(({ data }) => {
        console.log(data.resultObj)
        this.context.commit(Mutations.SET_ICON_FOOTER, data.resultObj)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
  @Action
  [Actions.EDIT_ICON_FOOTER](payload) {
    return ApiService.patch(`/admin/footer/icon/${payload.id}`, payload.data)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.validationErrors)
      })
  }
  @Action
  [Actions.UPDATE_FOOTER_SORT](payload) {
    this.context.commit(Mutations.SET_FOOTER_SORT, payload)
  }
}
